import React, { Component } from 'react';
import { history } from '../index';
import { withNamespaces } from 'react-i18next';

class Header extends Component {

  render() {
    return(
      <div>
        <header data-cy={ (this.props.questionNumber === this.props.questionsLength) ? 'lastQuestion' : 'question' }>
          <div className="questionnaire-brand" >
            {this.props.logo_url.length > 0 ?  <img src={this.props.logo_url}  alt="Logo Fragebogen"  title="CAPTain" /> :
              <span className="questionnaire-name">{this.props.title}</span> }
          </div>
        </header>
        <div className="progress">
          {this.props.allow_dummydata === true ? <div onClick={ () => history.push('/dummy') }> <div className="continue-later">{this.props.t('Fill Dummy Data')}</div><br /></div>
            : '' }
          {this.props.allow_later === true ? <div onClick={ () => history.push('/later') }><div className="continue-later">{this.props.t('Continue later')}</div></div>
            : '' }
          <div className="counter">
            <span className="question-counter">{this.props.t('Question')} {this.props.questionNumber} {this.props.t('from')}  {this.props.questionsLength}</span>
            <span className="modul-counter">{this.props.t('Module')} {this.props.modulNumber} {this.props.t('from')} {this.props.modulsLength} </span>
          </div>
        </div>
      </div>
    );
  }
}


export default withNamespaces()(Header);
